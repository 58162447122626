<template>
  <div>
    <Cover></Cover>
    <router-view style="min-height: 747px"></router-view>
  </div>
</template>

<script>
import Cover from "../../layouts/Cover";
import eventBus from "vue3-eventbus";
import state from "../../store/state";
import article from "../../api/article";
export default {
  name: "Product",
  data() {
    return {
      cover: {
        img: "https://oss.pigwan.net/wapi/upload/images/2021/12/product.jpg",
        title: "产品展示",
        description: "公司产品展示"
      },
      cate_id:2,
    };
  },
  components: {
    Cover
  },
  mounted() {
    eventBus.emit("cover", this.cover);
    //判断是否包含参数
    this.getArticleCate();
  },
  methods:{
    getArticleCate() {
      let website_id = state.websiteSet.website.id;
      article.getArticleCate(website_id,this.cate_id)
        .then((res) => {
          if (res.code === 0 && res.data.length > 0) {
            eventBus.emit("product_cate", res.data);
            eventBus.emit("product_cate_id", this.cate_id);
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>

</style>